import {useCallback, useContext} from 'react';
import {SurveyContext} from '../SurveyCreator';
import {DropResult} from 'react-beautiful-dnd';
import {topLevelTypeDropId} from './Blocks';
import _ from 'lodash';

export const useBlockDrag = () => {
    const {blocks, groups} = useContext(SurveyContext);

    function getBlockIdxBefore(result: DropResult) {
        // if block is moved up, we need to bias the index by -1
        const bias = result.source.index >= result.destination.index ? -1 : 0;
        return result.destination.index + bias;
    }

    function getTopLevelAfterId(result: DropResult) {
        const blockIdxBefore = getBlockIdxBefore(result);
        return _.last(groups.groupedBlocks()[blockIdxBefore]?.blocks)?.id || null;
    }

    function getBlockGroupBefore(lockGroupId: string) {
        const blockGroupBeforeIdx = groups.groupedBlocks().findIndex(group => group.id === lockGroupId) - 1;
        return groups.groupedBlocks()[blockGroupBeforeIdx];
    }

    function getNestedLevelAfterId(result: DropResult) {
        const destinationBlockGroup = groups.groupedBlocks().find(group => group.id === result.destination.droppableId);
        const blockBeforeIdx = getBlockIdxBefore(result);

        const blockGroupBefore = getBlockGroupBefore(destinationBlockGroup?.id);
        return destinationBlockGroup.blocks[blockBeforeIdx]?.id || _.last(blockGroupBefore?.blocks)?.id || null;
    }

    const onDragEnd = useCallback((result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const draggableId = result.draggableId;

        if (result.type === topLevelTypeDropId) {
            const context = blocks.byId(draggableId) ? blocks : groups;
            const afterId = getTopLevelAfterId(result);
            context.move(draggableId, afterId)
        } else {
            const afterId = getNestedLevelAfterId(result);
            blocks.move(draggableId, afterId)
        }
    }, [blocks, groups]);

    return { onDragEnd }
}