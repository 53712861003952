import * as React from 'react';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import styles from './GroupComponent.pcss';
import {Button} from '../../components/Button/Button';
import {ArrowDownIcon, ArrowUpIcon} from '../../icons';
import {GroupOptionsComponent} from './IntentGroupOptions';
import classNames from 'classnames';
import {ConfirmCancelOptionsMenuComponent} from './optionsMenu/ConfirmCancelOptionsMenu';
import {calculateItemsInGroupHeight} from './utils';

interface GroupComponentProps {
    groupId: string
    groupName: string
    itemsLength: number
    dataTest: string
    onRemoveGroup: (groupId: string) => void
    onUngroup?: (groupId: string) => void
    onEditName?: (groupName: string) => void
    className?: string
    onEditToggle?: (value: boolean) => void;
    groupRef?: React.RefObject<HTMLDivElement>;
}

const restoreFromLocalStorage = (key: string): string[] => {
    const collapsedFromStorage = localStorage.getItem(key);
    return collapsedFromStorage ? JSON.parse(collapsedFromStorage) : [];
};
const addCollapsedGroupToLocalStorage = (groupId: string, key: string) => {
    const oldGroups = restoreFromLocalStorage(key);
    const newGroups = oldGroups.includes(groupId) ? oldGroups.filter(g => g !== groupId) : [...oldGroups, groupId];
    localStorage.setItem(key, JSON.stringify(newGroups));
};

const removeCollapsedGroupFromLocalStorage = (groupId: string, key: string) => {
    const oldGroups = restoreFromLocalStorage(key);
    const newGroups = oldGroups.filter(g => g !== groupId)
    localStorage.setItem(key, JSON.stringify(newGroups));
}

export const GroupComponent: FC<GroupComponentProps> = ({
                                                            children,
                                                            groupId,
                                                            groupName,
                                                            itemsLength,
                                                            onRemoveGroup,
                                                            dataTest,
                                                            onUngroup,
                                                            onEditName,
                                                            className,
                                                            onEditToggle,
                                                            groupRef
                                                        }) => {
    const [collapsed, setCollapsed] = useState<string[]>(restoreFromLocalStorage(`collapsed-creator-${dataTest}`));
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(groupName)
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isEditing) {
            setIsEditing(isEditing);
            if (isEditing) {
                setFocus();
            }
        }
        if (onEditToggle) {
            onEditToggle(isEditing);
        }
    }, [isEditing]);

    const setFocus = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }

    const handleEditName = useCallback(() => {
        if (onEditName) {
            setIsEditing(true);
        }
    }, [onEditName]);

    const confirmEditClick = useCallback(() => {
        setIsEditing(false);
        onEditName(name);
    }, [onEditName, name]);


    const cancelEditClick = useCallback(() => {
        setIsEditing(false);
    }, []);

    const toggleCollapsedGroup = useCallback((gId: string) => {
        setCollapsed((prevCollapsed) => {
            addCollapsedGroupToLocalStorage(gId, `collapsed-creator-${dataTest}`);
            return prevCollapsed.includes(gId) ? prevCollapsed.filter(item => item !== gId) : [...prevCollapsed, gId];
        });
    }, [collapsed]);

    const collapsedGroup = collapsed.includes(groupId);
    const itemsHeight = calculateItemsInGroupHeight(collapsedGroup, itemsLength);


    const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            cancelEditClick();
        } else if (['Enter', 'Tab'].includes(event.key)) {
            confirmEditClick();
        }
    }, [confirmEditClick, cancelEditClick]);

    return (
        <div data-test={dataTest} ref={groupRef}>
            <div className={classNames(styles.groupHeader, className, {[styles.active]: isEditing})} data-test={`${dataTest}-header`} onDoubleClick={handleEditName}>
                <Button className={styles.collapseToggle} onClick={() => toggleCollapsedGroup(groupId)} data-test={'group-collapse-toggle'}>
                    {collapsedGroup ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </Button>
                {isEditing ? (
                    <div className={styles.editNameContainer}>
                        <input
                            ref={inputRef}
                            className={styles.editNameInput}
                            type="text"
                            value={name}
                            onChange={(event => setName(event.target.value))}
                            onKeyDown={onKeyDown}
                            data-test="name-input"
                        />

                        <ConfirmCancelOptionsMenuComponent
                            positionClassName={styles.confirmContainerPosition}
                            dataTest={'isEditing-options-menu'}
                            onConfirm={confirmEditClick}
                            onCancel={cancelEditClick}
                        />
                    </div>
                ) : (
                    <div data-test={`${dataTest}-title`} className={styles.groupTitle}>
                        {groupName}
                    </div>)
                }

                <div data-test={'group-options'} className={styles.groupOptions}>
                    <GroupOptionsComponent onDelete={() => {
                        removeCollapsedGroupFromLocalStorage(groupId, `collapsed-creator-${dataTest}`)
                        onRemoveGroup(groupId);
                    }} onUngroup={onUngroup ? () => onUngroup(groupId) : undefined} onEditStart={onEditName && handleEditName} />
                </div>
            </div>

            <div className={classNames(styles.groupItems, {[styles.expandedGroup]: !collapsedGroup})} style={{height: itemsHeight}} data-test={collapsedGroup ? `collapsed-${dataTest}` : `displayed-${dataTest}`}>
                {children}
            </div>
        </div>
    )
}