import { useContext } from 'react';
import uuid from 'uuid';
import {SurveyDefinitionVariable, SystemVariable} from '../../model';

import {SurveyContext, SurveyContextType} from '../../SurveyCreator';

export const useVariableCreation = (setVariable: (id: string, selectedVariable: SurveyDefinitionVariable) => void, variableId?: string) => {
  const {variables} = useContext<SurveyContextType>(SurveyContext);
  const options: SurveyDefinitionVariable[] = variables.get()
    .filter(variable => variable.origin === 'step' || variable.origin === 'contact' || variable.origin === 'external' || (variable.origin === 'system' && (variable as SystemVariable).isWritable))

  const handleCreate = (variableName: string) => {
    const id = uuid.v4();
    variables.changePendingVariableUsage(variableId);
    const variable: SurveyDefinitionVariable = {id, name: variableName, origin: 'step'};
    variables.addPendingVariable(variable);
    setVariable(id, variable);
  };

  const handleSelect = (selectedId: string) => {
    variables.changePendingVariableUsage(variableId, selectedId);
    setVariable(selectedId, options.find(v => v.id === selectedId));
  }

  const handleRename = async (currentId: string, newName: string) => {
    await variables.changeName(currentId, newName);
    setVariable(currentId, options.find(v => v.id === currentId));
  }

  const selectedOption = options.find(option => variableId === option.id) ?? null;
  return {options, handleCreate, handleSelect, handleRename, selectedOption};
};
