import cx from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../../../components/Button/Button';
import { ErrorPopup } from '../../../components/ErrorPopup/ErrorPopup';
import { IconButton } from '../../../components/IconButton/IconButton';
import { SelectComponent } from '../../../components/Select/Select';
import { CancelIcon } from '../../../icons';
import { IconProps } from '../../../icons/iconTypes';
import { VariablesIcon } from '../../../icons';
import { SurveyDefinitionVariable, Variable } from '../../model';
import { OptionsComponent } from '../optionsMenu/Options';
import { MenuOption } from '../optionsMenu/OptionsMenu';
import { PillComponent } from '../pill/Pill';

import styles from './Variables.pcss';
import { variablesMenuOptionsFactory } from './Variables.utils';
import { sortByNameComparator } from '../../../utils/SortUtils';

export const originToBadgeMap = {
  step: undefined,
  system: 'SYS',
  contact: 'CON',
  external: 'EXT',
};

type Props = {
  className?: string;
  isOnlyChild?: boolean;
  fluid?: boolean;
  filter?: (v: SurveyDefinitionVariable) => boolean;
  selectedOption: Variable;
  options: SurveyDefinitionVariable[];
  handleSelect: (selectedId: string) => void;
  handleCreate: (variableName: string) => void;
  handleRename: (currentId: string, newName: string) => void;
};

const VariablesIconWrapper: React.FC<IconProps> = (props) => <span className={styles.prefix}>
  <VariablesIcon {...props} />
</span>

export const Variables = ({
  className,
  selectedOption,
  handleCreate,
  handleSelect,
  handleRename,
  options,
  isOnlyChild = false,
  fluid = false,
  filter = () => true
}: Props) => {
  const intl = useIntl();

  const [selecting, setSelecting] = useState<boolean>(false);
  const [hasError, setError] = useState<boolean>(false);

  const setInitialVariable = () => {
    setSelecting(true);
  };

  const createNew = (value: string) => {
    handleCreate(value);
    setSelecting(false);
  }

  const select = (value: string) => {
    handleSelect(value);
    setSelecting(false);
  }

  const handleOpenVariableChange = () => {
    setSelecting(true);
  }

  const handleNameChange = async (value: string) => {
    await handleRename(selectedOption.id, value);
  };

  const handleNameUpdate = async (value: string) => {
    setError(value !== selectedOption?.name && options.map(option => option.name).includes(value));
  }

  const dropdownIndicator = () =>
    <IconButton Icon={CancelIcon} dataTest="abort-creation" onClick={() => setSelecting(false)} />

  const onlyChildStyles = { [styles.onlyChild]: isOnlyChild };

  return (
    <div className={cx(styles.container, { 'selecting': selecting, [className]: !!className })}>
      {!selectedOption && (
        <Button
          className={cx(styles.selectButton, onlyChildStyles, { [styles.fluid]: fluid })}
          dataTest={'select-or-create-new-variable'}
          buttonType={'normal'}
          onClick={setInitialVariable}>{intl.messages['survey-creator.createVariableButton']}
        </Button>
      )}
      {selectedOption && (
        <PillComponent
          hasError={hasError}
          type={'regular'}
          onSelect={_.noop}
          item={selectedOption.id}
          itemName={selectedOption.name}
          itemBadge={originToBadgeMap[selectedOption.origin]}
          dataTest={'variable-value'}
          PrefixIcon={VariablesIconWrapper}
          onItemNameChange={handleNameChange}
          onItemNameUpdate={handleNameUpdate}
          isEditable
          renderOptions={
            (params) => {
              const variablesMenuOptions = variablesMenuOptionsFactory({
                onVariableChange: handleOpenVariableChange,
                onRename: params.onEditStart
              });
              return <OptionsComponent
                containerPositionClassName={styles.optionsContainer}
                options={variablesMenuOptions.map(optionProps => <MenuOption
                  key={optionProps.key}
                  {...optionProps}
                />)} />;
            }
          }
          classes={{
            root: cx(styles.pill, onlyChildStyles),
            prefixEditIcon: styles.prefixEditIcon,
            confirmContainer: styles.confirmContainer,
            editableContainer: styles.editableContainer
          }}
        />
      )}
      {selecting && (
        <div className={cx(styles.select, onlyChildStyles)} data-test="select-or-create-list">
          <SelectComponent
            value={null}
            options={options
              .filter(filter)
              .sort(sortByNameComparator)
              .map((option) => ({ ...option, badge: originToBadgeMap[option.origin] }))}
            onChange={({ id }) => select(id)}
            onCreate={createNew}
            createOptionMessageId={'survey-creator.createVariableLabel'}
            isCreatable
            isOpen
            autoFocus
            components={{
              DropdownIndicator: dropdownIndicator
            }}
          />
        </div>
      )}
      {
        hasError && <ErrorPopup
          data-test="error"
          messageId="survey-creator.saveAs.renameVariableOptionError"
        />
      }
    </div>
  )
}
