import cx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';

import { IconProps } from '../../../icons/iconTypes';
import { StartpointIcon } from '../../../icons';
import { Badge } from '../../../components/Badge/Badge';
import { ConfirmCancelOptionsMenuComponent } from '../optionsMenu/ConfirmCancelOptionsMenu';
import { useOutsideClick } from '../useOutsideClick';

import styles from './Pill.pcss';

type PillType = 'regular' | 'primary'

type PillClasses = Partial<{
    root: string;
    prefix: string;
    prefixEditIcon: string;
    confirmContainer: string;
    editableContainer: string;
}>;

type PillProps<T = any> = {
    item: T;
    itemName: string;
    itemBadge?: string;
    isFirst?: boolean;
    isEditing?: boolean;
    hasError?: boolean;
    selected?: boolean;
    someBlockEdit?: boolean;
    onEditToggle?: (value: boolean) => void;
    isEditable?: boolean;
    onSelect: (item: T) => void;
    onItemNameChange?: (name: string, item: T) => void;
    onItemNameUpdate?: (name: string) => void;
    renderOptions?: RenderOptions;
    dataTest?: string;
    draggable?: DraggableProvided;
    type?: PillType;
    PrefixIcon?: React.FC<IconProps>;
    classes?: PillClasses;
    onCancel?: () => void;
    groupRef?: React.RefObject<HTMLDivElement>;
}

export type RenderOptions = (params: RenderOptionsParams) => JSX.Element

export interface RenderOptionsParams {
    onEditStart?: () => void;
}

export const PillComponent = ({
    onSelect, item, itemName, itemBadge, selected,
    dataTest, onItemNameChange, onItemNameUpdate, 
    onEditToggle, isEditable = !!onEditToggle, isEditing = undefined,
    someBlockEdit, isFirst, renderOptions, draggable,
    type = 'regular', PrefixIcon, classes, hasError = false, onCancel, groupRef
}: PillProps) => {
    const [name, _setName] = useState<string>(itemName);
    const setName = useCallback((value: string) => {
        _setName(value);
        if (onItemNameUpdate) {
            onItemNameUpdate(value);
        }
    }, [onItemNameUpdate, _setName]);

    useEffect(() => {
        setName(itemName);
    }, [itemName]);
    const [isEdit, toggleEdit] = useState<boolean>(false);

    useEffect(() => {
        if (selected) {
            if (groupRef?.current) {
                groupRef?.current?.scrollIntoView?.();
            } else {
                blockRef.current?.scrollIntoView?.();
            }
        }
    }, [groupRef]);

    useEffect(() => {
        if (isEditing !== undefined) {
            toggleEdit(isEditing);
            if (isEditing) {
                setFocus();
            }
        }
    }, [isEditing]);

    const setFocus = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }

    const blockRef = useRef(null);
    const { removeHandler, addHandler } = useOutsideClick(blockRef, () => cancelEditClick());
    useEffect(() => {
        if (onEditToggle) {
            onEditToggle(isEdit);
        }
        if (!isEdit) {
            removeHandler();
        }
    }, [isEdit]);
    const inputRef = useRef(null);

    const setBlockRef = (ref) => {
        blockRef.current = ref;
        if (draggable) {
            draggable.innerRef(ref);
        }
    };
    const onKeyDown = (event: any) => {
        if (event.key === 'Escape') {
            cancelEditClick();
        } else if (['Enter', 'Tab'].includes(event.key) && !hasError) {
            confirmEditClick();
        } else if (event.key === 'Tab' && hasError) {
            event.preventDefault();
        }
    };

    const cancelEditClick = () => {
        toggleEdit(false);
        setName(itemName);
        if (Boolean(onCancel)) {
          onCancel();
        }
    };
    const confirmEditClick = async () => {
        toggleEdit(false);
        await onItemNameChange(name, item);
    };

    const primaryStyle = { [styles.primary]: type === 'primary' };
    const mainClasses = cx(
        classes?.root,
        styles.item,
        {
            [styles.selected]: selected,
            [styles.hasError]: hasError,
            [styles.disabled]: someBlockEdit && !isEdit,
            [styles.edit]: isEdit,
            [styles.view]: !isEdit,
            [styles.withPrefix]: !!PrefixIcon,
            [classes?.editableContainer]: isEdit
        },
        primaryStyle
    );
    const renderInput = () => <input
        type="text"
        ref={inputRef}
        className={cx(styles.nameInput, primaryStyle)}
        value={name}
        onChange={(event => setName(event.target.value))}
        onKeyDown={onKeyDown}
        data-test="name-input"
    />;
    return (
        <div
            {...(draggable ? { ...draggable.draggableProps, ...draggable.dragHandleProps } : {})}
            data-test={dataTest}
            onClick={() => onSelect(item)}
            {...(isEditable ? { onDoubleClick: () => {
                toggleEdit(true)
                setFocus();
            } } : {})}
            className={mainClasses}
            data-selected={selected}
            ref={setBlockRef}
        >
            <div className={cx(styles.firstNameContainer, { [styles.edit]: isEdit, [styles.view]: !isEdit })}>
                {isFirst && <span data-test="first" className={styles.first}><StartpointIcon /></span>}
                {!isEdit ?
                    (
                        <>
                            <div data-test="name" className={cx(styles.name, primaryStyle)}>
                                {
                                    PrefixIcon
                                        ? (
                                            <div className={cx(classes?.prefix, styles.withPrefixContainer)}>
                                                <PrefixIcon animationClass={styles.prefixViewIcon} />
                                                <div className={styles.itemNameContainer}>
                                                    {itemBadge && <Badge size="small">{itemBadge}</Badge>}
                                                    <span data-test={`${dataTest}-text`}>{itemName}</span>
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <div className={styles.itemNameContainer}>
                                                {itemBadge && <Badge size="small">{itemBadge}</Badge>}
                                                <span data-test={`${dataTest}-text`}>{itemName}</span>
                                            </div>
                                      )
                                }
                            </div>
                            {
                                Boolean(renderOptions) &&
                                renderOptions({
                                    onEditStart: () => {
                                        addHandler();
                                        toggleEdit(true)
                                        setFocus();
                                    },
                                })
                            }
                        </>
                    ) :
                    (
                        <>
                            {PrefixIcon
                                ? (
                                    <div className={styles.withPrefixContainer}>
                                        <PrefixIcon animationClass={cx(styles.prefixEditIcon, classes?.prefixEditIcon)} />
                                        {renderInput()}
                                    </div>
                                )
                                : renderInput()
                            }
                            <ConfirmCancelOptionsMenuComponent
                                positionClassName={cx(
                                    styles.confirmContainerPosition,
                                    primaryStyle,
                                    classes?.confirmContainer
                                )}
                                dataTest={'edit-options-menu'}
                                onConfirm={confirmEditClick}
                                onCancel={cancelEditClick}
                                confirmDisabled={hasError}
                            />
                        </>
                    )}
            </div>
        </div>
    );
};
