import cx from 'classnames'
import React from 'react'

import { StandardInitialProps } from '../types'

import styles from './Badge.pcss'

export const Badge: React.FC<StandardInitialProps & { size?: 'small' }> = ({
  className,
  children,
  dataTest,
  size,
}) => {
  return (
    <div
      data-test={dataTest}
      className={cx(
        styles.badge,
        className,
        { [styles.small]: size === 'small' },
      )}>
      {children}
    </div>
  );
}
