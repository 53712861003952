import moment from 'moment';

export type LanguageCode = 'pl-PL' | 'en-US' | 'en-GB' | 'fr-FR' | 'hu-HU' | 'ru-RU' | 'en-AU';

export const interpretationApiLocale: Record<LanguageCode, string> = {
    'en-GB': 'en',
    'en-US': 'en',
    'en-AU': 'en',
    'pl-PL': 'pl',
    'fr-FR': 'fr',
    'ru-RU': 'ru',
    'hu-HU': 'hu'
};

export const convertToDateTimeString = (format: string, timezone: string, isoStringOrTimestamp?: string | number) => {
    if (!isoStringOrTimestamp) {
        return '';
    }

    if (typeof isoStringOrTimestamp === 'string' && /^[0-9]*$/.test(isoStringOrTimestamp)) {
        // detected timestamp as a string - converting to a number
        return moment.tz(Number(isoStringOrTimestamp), timezone).format(format);
    }

    return moment.tz(isoStringOrTimestamp, timezone).format(format);
}
