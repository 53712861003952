import _, { uniq } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import uuid, { v4 } from 'uuid';

import { HttpClient } from '../utils/HttpClient';

import { questionFactory } from './components/questions-factory';
import { systemVariableEntryName } from './components/variables/Variables.utils';
import {
  Block,
  BlockQuestion,
  Bot,
  Callbacks,
  Fallback,
  isConditionalStep,
  isDictionaryVariable,
  isSystemVariable,
  QuestionError,
  QuestionType,
  SurveyDefinitionVariable,
  SurveyIntent,
  SystemVariableScope,
  WordSurveyIntent,
  isWordSurveyIntent,
  isWordStep,
  WordAnswerType,
  slugifyIntentName,
  ExecutableFunctionDefinition,
  SystemVariable,
} from './model';
import {Group, Intent} from './SurveyCreator';
import { AxiosError } from 'axios';
import { buildCallbackBlocks, callbackName } from './useSurveyCreatorMappers';
import { useAllEntitiesValues } from './components/EntityDetails/hooks/useAllEntitiesValues';
import { useCreatorRouter } from './useCreatorRouter';

function extractEntitiesFromBotDefinition(
  steps: BlockQuestion[],
  intents: SurveyIntent[],
) {
  const primaryAnswerEntities = steps
    .filter(isWordStep)
    .map((step) => step.dictionary
      .filter((item) => item.type === WordAnswerType.ENTITY)
      .map((entityItem) => entityItem.id)
    ).flat();
  const asociatedAnswerEntities = steps
    .filter((step) => step.associatedAnswers)
    .map((step) => step.associatedAnswers.map(answer => answer.entity))
    .flat();
  const intentEntities = intents
    .flatMap(intent => intent.entities)
    .map(associatedEntity => associatedEntity.entity);

  return uniq([...primaryAnswerEntities, ...asociatedAnswerEntities, ...intentEntities]);
}

type QuestionsUpdater = (question: BlockQuestion[]) => BlockQuestion[];

const NON_VISIBLE_INTENTS = ['data', 'VOICE_MAIL', 'REPEAT_REQUEST'];

type PendingVariable = SurveyDefinitionVariable & { times?: number }

export const useSurveyCreator = (currentBot?: Bot) => {
  const intl = useIntl();
  const { current, navigateToIntent, navigateToNewQuestion, navigateToBlock, navigateToBlockQuestion, navigateToFallbackQuestion, navigateToSilenceFallbackQuestion, navigateToCallbackQuestion } = useCreatorRouter();
  const [definitionState, setDefinitionState] = useState<'loading' | 'success'>('loading');
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [fallback, setFallback] = useState<Fallback>();
  const [silenceFallback, setSilenceFallback] = useState<Fallback>();
  const [newQuestion, setNewQuestion] = useState<BlockQuestion>();
  const [newIntent, setNewIntent] = useState<SurveyIntent>();
  const [intents, setIntents] = useState<SurveyIntent[]>([]);
  const [callbacks, setCallbacks] = useState<Callbacks>({});
  const [allIntents, setAllIntents] = useState<Intent[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [globalSynonyms, setGlobalSynonyms] = useState<string[]>([]);
  const [variables, setVariables] = useState<SurveyDefinitionVariable[]>([]);
  const [pendingVariablesToSave, setPendingVariableToSave] = useState<PendingVariable[]>([]);
  const [validationErrors, setValidationErrors] = useState<Array<QuestionError>>([]);
  const [executableFunctionDefinitions, setExecutableFunctionDefinitions] = useState<ExecutableFunctionDefinition<any, any>[]>([]);
  const blocksRef = useRef<HTMLDivElement>(null);

  const selectedBlockId =
    !current.intentId &&
    !current.callbackId &&
    !current.fallback &&
    !current.silenceFallback &&
    (current.blockId ?? blocks[0]?.id);

  function resolveSelectedQuestionId() {
    // fallbacks
    if (current.fallbackStepId) {
      return current.fallbackStepId;
    }
    if (current.fallback) {
      return fallback?.steps?.[0]?.id;
    }

    // silence fallbacks
    if (current.silenceFallbackStepId) {
      return current.silenceFallbackStepId;
    }
    if (current.silenceFallback) {
      return silenceFallback?.steps?.[0]?.id;
    }

    // callbacks
    if (current.callbackStepId) {
      return current.callbackStepId;
    }
    if (current.callbackId) {
      return callbacks[current.callbackId]?.questions?.[0]?.id;
    }

    // blocks
    if (current.stepId) {
      return current.stepId;
    }
    if (current.blockId) {
      const block = blocks.find((b) => b.id === selectedBlockId);
      return block?.questions?.[0]?.id;
    }

    return blocks?.[0]?.questions?.[0]?.id;
  }

  const selectedQuestionId = resolveSelectedQuestionId();

  const currentBotId = _.get(currentBot, 'id');
  const resetState = () => {
    setPendingVariableToSave([]);
    removeNewQuestion();
  };

  const currentlySelectedBlock = () => {
    if (current.intentId || newIntent) {
      return;
    }
    if (current.blockId) {
      return blocks.find((block) => block.id === selectedBlockId);
    }
    if (current.fallback) {
      return getFallbackAsBlock();
    }
    if (current.silenceFallback) {
      return getSilenceFallbackAsBlock();
    }
    if (current.callbackId) {
      return callbacks[current.callbackId]
    }

    return blocks?.[0];
  };

  const currentlySelectedCallbackBlock = current.callbackId ? callbacks[current.callbackId] : undefined;

  const getAllQuestions = () => {
    if (currentlySelectedBlock()) {
      return _.flow(
        () => currentlySelectedBlock().questions,
        (questions) => (newQuestion ? [...questions, newQuestion] : questions)
      )()

    } else if (currentlySelectedCallbackBlock) {
      return _.flow(
        () => currentlySelectedCallbackBlock.questions,
        (questions) => (newQuestion ? [...questions, newQuestion] : questions)
      )()

    }
    return [];
  };



  function getFallbackAsBlock(): Block {
    return ({
      id: 'fallback',
      name: intl.messages['survey-creator.fallback'] as string,
      questions: fallback?.steps ?? []
    })
  }

  function getSilenceFallbackAsBlock(): Block {
    return ({
      id: 'silenceFallback',
      name: intl.messages['survey-creator.silenceFallback'] as string,
      questions: silenceFallback?.steps ?? []
    })
  }

  const allQuestions = getAllQuestions();

  const currentlySelectedQuestion = (currentlySelectedBlock() || currentlySelectedCallbackBlock) && (newQuestion || allQuestions.find(q => q.id === selectedQuestionId));
  const removeNewQuestion = () => setNewQuestion(undefined);
  const updateBlock = (updatedBlock: Block) => {
    setBlocks(prevBlocks => prevBlocks.map(block => block.id === updatedBlock.id ? updatedBlock : block));
  };
  const updateCallback = (callbackId: string, steps: BlockQuestion[]) => {
    setCallbacks({ ...callbacks, [callbackId]: { ...callbacks[callbackId], questions: steps } })
  };
  const addCallback = (callbackId: string) => {
    setCallbacks({
      ...callbacks,
      [callbackId]: {
        id: callbackId,
        name: callbackName(intl)(callbackId),
        questions: []
      }
    })
  };
  const removeCallback = (callbackId: string) => {
    setCallbacks(_.omit(callbacks, callbackId));
  };
  const updateCurrentBlockQuestions = (updater: QuestionsUpdater) => {
    if (current.blockId && currentlySelectedBlock()) {
      return updateBlock({
        ...currentlySelectedBlock(),
        questions: updater(currentlySelectedBlock().questions)
      })
    } else if (current.callbackId && currentlySelectedCallbackBlock) {
      return updateCallback(currentlySelectedCallbackBlock.id, updater(currentlySelectedCallbackBlock.questions))
    } else if (current.fallback) {
      return updateFallback({ steps: [...updater(fallback.steps)] })
    } else if (current.silenceFallback) {
      return updateSilenceFallback({ steps: [...updater(silenceFallback.steps)] })
    }
    return updateBlock({
      ...currentlySelectedBlock(),
      questions: updater(currentlySelectedBlock().questions)
    })
  }

  const updateFallback = (updatedFallback: Fallback) => {
    setFallback(updatedFallback);
  }

  const updateSilenceFallback = (updatedSilenceFallback: Fallback) => {
    setSilenceFallback(updatedSilenceFallback);
  }

  useEffect(() => {
    HttpClient.get({ path: `/executable-functions` }).then(result => {
      if (result) {
        setExecutableFunctionDefinitions(result.data.executableFunctions)
      }
    })
  }, []);

  useEffect(() => {
    Promise.allSettled([
      HttpClient.get({ path: `/bots/${currentBotId}/creator` }),
      HttpClient.get({ path: `/bots/${currentBotId}/intents` }),
      HttpClient.get({ path: `/bots/${currentBotId}/variables` }),
      HttpClient.get({ path: `/bots/${currentBotId}/global-synonyms` }),
    ]).then(([definitionResult, intentsResult, variablesResult, globalSynonymsResult]) => {
      resetState();

      if (definitionResult.status === 'fulfilled') {
        const { data } = definitionResult.value;
        setBlocks(data.blocks);
        setFallback(data.fallback);
        setSilenceFallback(data.silenceFallback);
        setIntents(data.intents);
        setCallbacks(buildCallbackBlocks(intl)(data.callbacks));
        setGroups(data.groups || []);
      }

      if (globalSynonymsResult.status === 'fulfilled') {
        setGlobalSynonyms(globalSynonymsResult.value.data?.map(element => element.toLowerCase()) || []);
      }

      if (variablesResult.status === 'fulfilled') {
        setVariables([...(variablesResult.value.data || [])]);
      }

      if (intentsResult.status === 'fulfilled') {
        setAllIntents(intentsResult.value.data.filter((el: Intent) => !NON_VISIBLE_INTENTS.includes(el.name)));
      }

      setDefinitionState('success');
    })
  }, [currentBotId]);


  const allQuestionsFromBlocks = () => _.flattenDeep(blocks.map(block => block.questions))

  const isFromDateTimeStep = (variable: SurveyDefinitionVariable) => allQuestionsFromBlocks().some(q => (q.type === QuestionType.DATETIME && q.saveTo === variable.id) || (isConditionalStep(q) && q.question.type === QuestionType.DATETIME) && q.question.saveTo === variable.id);

  const currentScope = (): SystemVariableScope => {
    if (!!current.callbackId) {
      if (callbacks.callNotAnswered?.id === current.callbackId) {
        return 'callbackCallNotAnswered';
      } else if (callbacks.callFinished?.id === current.callbackId) {
        return 'callbackCallFinished';
      } else {
        return 'callbackVoicemailDetected';
      }
    }
    return 'creator'
  }

  const variablesForCurrentScope = () => variables.filter(variable => !isSystemVariable(variable) || variable.scopes.includes(currentScope()));
  const getAllVariables = () => variablesForCurrentScope().map(variable => isSystemVariable(variable)
    ? {
      ...variable,
      name: !!intl.messages[`survey-creator.system_variable.${variable.id}`] ? intl.messages[`survey-creator.system_variable.${variable.id}`] as string : variable.id
    } as SystemVariable
    : variable
  )

  const getVariablesFor = (scope: SystemVariableScope) => {
    const variablesForScope = variablesForCurrentScope();

    if (scope === 'planner') {
      return _.uniqBy([
        ...variablesForScope.map(variable => ({ id: variable.id, name: variable.name })),
      ], 'id');
    }
    const variablesFromStepsAndContact = _.flattenDeep(variablesForScope
      .map(variable => {
        if (variable.origin === 'step' && isFromDateTimeStep(variable)) {
          return [
            { id: variable.id, name: variable.name, origin: variable.origin },
            { id: variable.id + '__from', name: variable.name + '__from', origin: variable.origin },
            { id: variable.id + '__to', name: variable.name + '__to', origin: variable.origin },
            { id: variable.id + '__partOfDay', name: variable.name + '__partOfDay', origin: variable.origin },
          ]
        }
        if (isSystemVariable(variable)) {
          return [{
            id: variable.id,
            name: !!intl.messages[`survey-creator.system_variable.${variable.id}`] ? intl.messages[`survey-creator.system_variable.${variable.id}`] as string : variable.id,
            dictionary: isDictionaryVariable(variable) ? variable.values.map(v => ({
              id: v,
              key: systemVariableEntryName(intl, variable.id, v)
            })) : undefined,
            origin: variable.origin,
          }]
        }
        return [{ id: variable.id, name: variable.name, origin: variable.origin }];
      }));
    return _.uniqBy([
      ...variablesFromStepsAndContact,
    ], 'id');
  }

  const variablesForCreator = useMemo(() => getVariablesFor('creator'), [variables, variablesForCurrentScope]);

  const savePendingVariables = async () => {
    if (pendingVariablesToSave.length > 0) {
      await HttpClient.post({
        path: `/bots/${currentBotId}/variables`,
        body: pendingVariablesToSave.map(({ times, ...rest }) => rest)
      });
      setVariables(previous => [...previous, ...pendingVariablesToSave]);
      setPendingVariableToSave([]);
    }
  }

  const handleStepMove = (questionToMove: BlockQuestion, sourceBlockId: 'fallback' | 'silenceFallback' | string, targetBlockId: 'fallback' | 'silenceFallback' | string) => {
    if (sourceBlockId === 'fallback') {
      updateFallback({ steps: fallback.steps.filter(step => step.id !== questionToMove.id) });
    } else if (sourceBlockId === 'silenceFallback') {
      updateSilenceFallback({ steps: silenceFallback.steps.filter(step => step.id !== questionToMove.id) });
    } else {
      const modifiedBlock = blocks.find(block => block.id === sourceBlockId);
      updateBlock({ ...modifiedBlock, questions: modifiedBlock.questions.filter(question => question.id !== questionToMove.id) });
    }

    if (targetBlockId === 'fallback') {
      updateFallback({ steps: [...fallback.steps, questionToMove] });
    } else if (targetBlockId === 'silenceFallback') {
      updateSilenceFallback({ steps: [...silenceFallback.steps, questionToMove] });
    } else {
      const modifiedBlock = blocks.find(block => block.id === targetBlockId);
      updateBlock({ ...modifiedBlock, questions: [...modifiedBlock.questions, questionToMove] });
    }
  }

  const currentIntent = newIntent || (!!current.intentId ? intents.find(intent => slugifyIntentName(intent.name) === current.intentId) : undefined);
  const updateIntent = async (intentToUpdate: SurveyIntent) => {
    await HttpClient.put({
      path: `/bots/${currentBotId}/creator/intents/${encodeURIComponent(intentToUpdate.name)}`,
      body: intentToUpdate
    });
  };
  const changeIntentGroup = async function (intentName: string, group: string) {
    const intentToChange = intents.find(intent => intent.name === intentName);
    const changedIntent: SurveyIntent = {
      ...intentToChange,
      group
    };
    setIntents(prevIntents => prevIntents.map(item => item.name === changedIntent.name ? changedIntent : item));
    await updateIntent(changedIntent);
  };

  const handleErrors = (questionToSave: BlockQuestion, error: AxiosError<QuestionError[]>) => {
    if (error.response.status !== 400) {
      return;
    }
    if (questionToSave.type === QuestionType.CONDITIONAL) {
      const mapped = error.response.data
        .map((validationError) => {
          if (validationError.stepId === questionToSave.id && validationError.field.startsWith('question.')) {
            return ({ ...validationError, field: validationError.field.substring('question.'.length) });
          }
          return validationError;
        });
      setValidationErrors(mapped);
    } else {
      setValidationErrors(error.response.data)
    }
  };

  const resetValidation = () => setValidationErrors([])

  const scrollToModule = (id: string) => {
    if (blocksRef.current) {
      const element = blocksRef.current.querySelector(`[data-rbd-draggable-id='${id}']`);
      if (element && element.scrollIntoView) {
        element.scrollIntoView();
      }
    }
  };

  // steps
  const allSteps = useMemo(() => [
    ...blocks,
    getFallbackAsBlock(),
    getSilenceFallbackAsBlock(),
    ...(intents
      .filter(isWordSurveyIntent))
      .map(intent => ({
        ...intent,
        questions: [intent.question]
      }))
  ]
    .map(el => el.questions)
    .flat()
    .map(q => isConditionalStep(q) ? q.question : q),
    [blocks, fallback, silenceFallback, intents])

  // entities
  const [entitiesUsedInAnswers, setEntitiesUsedInAnswers] = useState<string[]>([])

  useEffect(() => {
    const newEntitiesUsedInAnswers = extractEntitiesFromBotDefinition(allSteps, intents)
    if (!_.isEqual(entitiesUsedInAnswers, newEntitiesUsedInAnswers)) {
      setEntitiesUsedInAnswers(newEntitiesUsedInAnswers)
    }
  }, [allSteps, intents])

  const entitiesDetails = useAllEntitiesValues(entitiesUsedInAnswers)

  return {
    allIntents,
    definition: {
      variables: {
        get: () => pendingVariablesToSave ? [...getAllVariables(), ...pendingVariablesToSave] : getAllVariables(),
        getVariablesForCreator: () => variablesForCreator,
        getVariablesForPlanner: () => getVariablesFor('planner'),
        addPendingVariable(variable: SurveyDefinitionVariable) {
          setPendingVariableToSave(prev => [...prev, { ...variable, times: 1 }])
        },
        changePendingVariableUsage(oldId: string, newId?: string) {
          setPendingVariableToSave(prev =>
            prev
              .map(pendingVariable => {
                if (pendingVariable.id === newId) {
                  return { ...pendingVariable, times: pendingVariable.times + 1 };
                }
                if (pendingVariable.id === oldId) {
                  return { ...pendingVariable, times: pendingVariable.times - 1 };
                }
                return pendingVariable;
              })
              .filter(pendingVariable => pendingVariable.times > 0)
          )
        },
        clearPendingVariables() {
          setPendingVariableToSave([])
        },
        changeName: async (currentId: string, newName: string) => {
          let updatedVariable;
          const mapState = (preserveVariable) => (prev) => prev
            .map(variable => {
              if (variable.id === currentId) {
                if (preserveVariable) {

                  updatedVariable = variable;
                }
                return {
                  ...variable,
                  name: newName
                }
              }
              return variable;
            })

          await setPendingVariableToSave(mapState(false));
          await setVariables(mapState(true));
          if (updatedVariable) {
            await HttpClient.put({
              path: `/bots/${currentBotId}/variables/${currentId}`, body: {
                ...updatedVariable,
                name: newName
              }
            });
          }
        },
        savePendingVariables
      },
      globalSynonyms: {
        get: () => globalSynonyms
      },
      blocks: {
        current: currentlySelectedBlock(),
        get: () => [...blocks],
        byId: (id: string) => blocks.find(block => block.id === id),
        add: async (newBlock: Block) => {
          const selectedBlockExists = blocks.map(b => b.id).includes(current.blockId);
          const body = {
            ...newBlock,
            afterId: selectedBlockExists ? current.blockId : blocks[blocks.length - 1]?.id
          }

          await HttpClient.post({ path: `/bots/${currentBotId}/creator/blocks`, body });

          setBlocks((prevBlocks) => reorderBlocks([...prevBlocks, newBlock], newBlock.id, body.afterId));
          navigateToBlock(newBlock.id)
          scrollToModule(newBlock.id);
        },
        clone: async (block: Block) => {
          const adjustQuestion = _.flow(
            (question: BlockQuestion) => ({ ...question, id: v4() }),
            (question: BlockQuestion) => question.type === QuestionType.CONDITIONAL ? _.set(question, 'question.id', v4()) : question,
          );
          const adjustedBlock = {
            ...block,
            id: v4(),
            name: `${block.name} (${intl.messages['survey-creator.cloneLabel']})`,
            questions: block.questions.map(adjustQuestion),
            afterId: block.id
          }

          setBlocks((prevBlocks) => reorderBlocks([...prevBlocks, adjustedBlock], adjustedBlock.id, adjustedBlock.afterId));
          await HttpClient.post({ path: `/bots/${currentBotId}/creator/blocks`, body: adjustedBlock });
        },
        remove: async (removed: Block) => {
          setBlocks((prevBlocks) => prevBlocks.filter(block => block.id !== removed.id));
          await HttpClient.delete({ path: `/bots/${currentBotId}/creator/blocks/${removed.id}` });
        },
        rename: async (blockId: string, newName: string) => {
          setBlocks((prevBlocks) => prevBlocks.map(block => block.id === blockId ? { ...block, name: newName } : block));
          await HttpClient.patch({
            path: `/bots/${currentBotId}/creator/v2/blocks`,
            body: {blockId, name: newName}
          })
        },
        update: async (updatedBlock: Block) => {
          await HttpClient.put({
            path: `/bots/${currentBotId}/creator/blocks/${updatedBlock.id}`,
            body: updatedBlock
          });
          updateBlock(updatedBlock);
        },
        setAsFirst: async (block: Block) => {
          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/blocks/${block.id}/setFirst`,
            body: {}
          });
          setBlocks((prevBlocks) => [block, ...prevBlocks.filter(el => el.id !== block.id)]);
        },
        nameOf: (blockId: string) => _.get(blocks.find(block => block.id === blockId), 'name', ''),
        available: () => [...blocks],
        move: async (blockId: string, afterId: string) => {
          setBlocks((prevBlocks) => reorderBlocks(prevBlocks, blockId, afterId));
          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/blocks/move`,
            body: {blockId, afterId}
          })
        },
        addToGroup: async (blockId: string, groupId: string) => {
          const lastBlockInGroup = _.findLast(blocks, block => block.groupId === groupId);
          const afterId = lastBlockInGroup?.id

          setBlocks((prevBlocks) => {
            const grouped = addBlockToGroup(prevBlocks, blockId, groupId);
            return reorderBlocks(grouped, blockId, afterId);
          });

          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/blocks/add-to-group`,
            body: {blockId, groupId, afterId}
          })
        },

        removeFromGroup: async (blockId: string) => {
          const currentGroupId = blocks.find(block => block.id === blockId)?.groupId;
          const lastBlockInGroup = _.findLast(blocks.filter(b => b.id !== blockId), block => block.groupId === currentGroupId);
          let afterId = null;
          if (lastBlockInGroup) {
            afterId = lastBlockInGroup.id
          } else {
            setGroups((prevGroups) => removeGroup(prevGroups, currentGroupId));
            const currentBlockIndex = blocks.findIndex(b => b.id === blockId)
            if (currentBlockIndex > 0) {
              afterId = blocks[currentBlockIndex - 1]?.id
            }
          }

          setBlocks((prevBlocks) => {
            const withoutGroup = removeBlocksFromGroup(prevBlocks, [blockId]);
            return reorderBlocks(withoutGroup, [blockId], afterId);
          });

          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/blocks/remove-from-group`,
            body: {blockId, afterId}
          })
        },

        groupBlock: async ( blockId: string, name: string) => {
          const tmpGroup = {
            id: `tmp-${v4()}`,
            name
          }

          setBlocks((prevBlocks) => {
            return addBlockToGroup(prevBlocks, blockId, tmpGroup.id);
          })
          setGroups((prevGroups) => {
            return [...prevGroups, tmpGroup]
          })

          const {data: newGroup} = await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/blocks/group`,
            body: {blockId, name}
          })

          setGroups((prevGroups) => {
            return [...prevGroups.filter(p => p.id !== tmpGroup.id), newGroup]
          })
          setBlocks((prevBlocks) => {
            return addBlockToGroup(removeBlocksFromGroup(prevBlocks, [blockId]), blockId, newGroup.id);
          });
        },

        savePosition: async (blockId: string, position: { x: number, y: number }) => {
          await HttpClient.patch({
            path: `/bots/${currentBotId}/creator/v2/blocks`,
            body: {blockId, position}
          })
        },
        isFirst(id: string) {
            return blocks[0]?.id === id;
        }
      },
      groups: {
        get: () => groups || [],
        byId: (groupId: string) => groups.find(group => group.id === groupId),
        move: async ( groupId: string, afterId: string ) => {
          setBlocks((prevBlocks) => {
            return reorderGroups(prevBlocks, groupId, afterId);
          })
          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/groups/move`,
            body: {groupId, afterId}
          })
        },
        update: async (groupId: string, {name}: {  name: string }) => {
          setGroups((prevGroups) => {
            return prevGroups.map(group => group.id === groupId ? { ...group, name } : group);
          });
          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/groups/update`,
            body: {groupId, name}
          })
        },
        ungroup: async (groupId: string) => {
          const blocksInGroupIds = blocks.filter(block => block.groupId === groupId).map(block => block.id);

          setBlocks((prevBlocks) => {
            return removeBlocksFromGroup(prevBlocks, blocksInGroupIds);
          });
          setGroups((prevGroups) => removeGroup(prevGroups, groupId));

          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/groups/ungroup`,
            body: {groupId}
          })
        },
        remove: async (groupId: string ) => {
          const blocksInGroupIds = blocks.filter(block => block.groupId === groupId).map(block => block.id);

          setBlocks((prevBlocks) => {
            return removeBlocks(prevBlocks, blocksInGroupIds);
          });
          setGroups((prevGroups) => removeGroup(prevGroups, groupId));
          await HttpClient.post({
            path: `/bots/${currentBotId}/creator/v2/groups/remove`,
            body: {groupId}
          })
        },

        groupedBlocks: () => {
          const groupedBlocksList: BlockGroup[] = [];
          blocks.forEach((block) => {
            if (!block.groupId) {
              groupedBlocksList.push({id: block.id, name: block.name, type: 'module', blocks: [block]});
            } else {
              const lastGroupedBlock = groupedBlocksList[groupedBlocksList.length - 1];
              if (!lastGroupedBlock || lastGroupedBlock.id !== block.groupId) {
                const group = groups.find(g => g.id === block.groupId)
                groupedBlocksList.push({id: group?.id, name: group?.name, type: 'group', blocks: [block]});
              } else {
                lastGroupedBlock.blocks.push(block);
              }
            }
          });

          return groupedBlocksList;
        },
        isInGroup(blockId: string) {
            return blocks.some(block => block.id === blockId && block.groupId);
        }
      },
      fallback: {
        get: () => fallback,
        getAsBlock: () => getFallbackAsBlock(),
        selected: current.fallback,
        update: async (block: Block) => {
          updateFallback({ steps: block.questions });
          await HttpClient.put({
            path: `/bots/${currentBotId}/creator/fallback`,
            body: { steps: block.questions }
          })
        },
      },
      silenceFallback: {
        get: () => silenceFallback,
        getAsBlock: () => getSilenceFallbackAsBlock(),
        selected: current.silenceFallback,
        update: async (block: Block) => {
          updateSilenceFallback({ steps: block.questions });
          await HttpClient.put({
            path: `/bots/${currentBotId}/creator/silence-fallback`,
            body: { steps: block.questions }
          })
        }
      },
      questions: {
        resetValidation,
        withError: (questionId: string, questionMessageType: string) => {
          return validationErrors
            .filter(({ field, stepId }) => stepId === questionId && field.startsWith(questionMessageType))
            .map(error => ({
              ...error,
              error: intl.messages[`survey.${questionMessageType}.error`] as string || error.error
            }));
        },
        get: () => allQuestions,
        current: currentlySelectedQuestion,
        close: () => {
          removeNewQuestion();
        },
        add: (questionToAdd: BlockQuestion) => {
          setNewQuestion(questionToAdd);
          navigateToNewQuestion(questionToAdd.id)
        },
        move: async (questionToMove: BlockQuestion, sourceBlockId: string | 'silenceFallback' | 'fallback', targetBlockId: string | 'silenceFallback' | 'fallback') => {
          const isMovingFromOrToFallback = current.fallback || targetBlockId === 'fallback';
          const isMovingFromOrToSilenceFallback = current.silenceFallback || targetBlockId === 'silenceFallback';
          const isMovingBetweenBlocks = selectedBlockId && !(['fallback', 'silenceFallback'].includes(targetBlockId));

          const resolvePath = () => {
            if (isMovingFromOrToFallback) {
              return `/bots/${currentBotId}/creator/fallback/step/move`;
            } else if (isMovingFromOrToSilenceFallback) {
              return `/bots/${currentBotId}/creator/silence-fallback/step/move`;
            } else if (isMovingBetweenBlocks) {
              return `/bots/${currentBotId}/creator/blocks/questions/move`;
            }
            return `/bots/${currentBotId}/creator/callbacks/steps/move`;
          }

          const path = resolvePath();
          const body = { question: questionToMove, sourceBlockId, targetBlockId };

          await HttpClient.post({
            path,
            body
          });

          updateCurrentBlockQuestions(questions => questions.filter(question => question.id !== questionToMove.id));
          if (isMovingBetweenBlocks) {
            const targetBlock = blocks.find(block => block.id === targetBlockId);
            updateBlock({ ...targetBlock, questions: [...targetBlock.questions, questionToMove] });
          } else if (isMovingFromOrToFallback || isMovingFromOrToSilenceFallback) {
            handleStepMove(questionToMove, sourceBlockId, targetBlockId);
          } else {
            const targetCallback = callbacks[targetBlockId];
            updateCallback(targetBlockId, [...targetCallback.questions, questionToMove])
          }
        },
        remove: async (questionToRemove: BlockQuestion) => {
          const path = () => {
            if (current.blockId) {
              return `/bots/${currentBotId}/creator/blocks/${current.blockId}/questions/${questionToRemove.id}`;
            }
            if (current.fallback) {
              return `/bots/${currentBotId}/creator/fallback/step/${questionToRemove.id}`;
            }
            if (current.silenceFallback) {
              return `/bots/${currentBotId}/creator/silence-fallback/step/${questionToRemove.id}`;
            }
            if (current.callbackId) {
              return `/bots/${currentBotId}/creator/callbacks/${current.callbackId}/steps/${questionToRemove.id}`;
            }
            return `/bots/${currentBotId}/creator/blocks/${selectedBlockId}/questions/${questionToRemove.id}`;
          }

          await HttpClient.delete({ path: path() });
          updateCurrentBlockQuestions(questions => questions.filter(question => question.id !== questionToRemove.id));
        },
        clone: (name?: string) => async (questionToClone: BlockQuestion) => {
          const adjustedQuestion = _.flow(
            (question: BlockQuestion) => ({ ...question, name, id: uuid.v4() }),
            (question: BlockQuestion) => _.omit(question, ['saveTo', 'question.saveTo']) as BlockQuestion,
            (question: BlockQuestion) => questionToClone.type === QuestionType.CONDITIONAL ? _.set(question, 'question.id', uuid.v4()) : question,
          )(questionToClone);

          const resolvePath = () => {
            if (current.blockId) {
              return `/bots/${currentBotId}/creator/blocks/${current.blockId}/questions/${questionToClone.id}/clone`;
            }
            if (current.fallback) {
              return `/bots/${currentBotId}/creator/fallback/step/${questionToClone.id}/clone`;
            }
            if (current.silenceFallback) {
              return `/bots/${currentBotId}/creator/silence-fallback/step/${questionToClone.id}/clone`;
            }
            if (current.callbackId) {
              return `/bots/${currentBotId}/creator/callbacks/${current.callbackId}/steps/${questionToClone.id}/clone`;
            }
            return `/bots/${currentBotId}/creator/blocks/${selectedBlockId}/questions/${questionToClone.id}/clone`;
          }
          const path = resolvePath();

          await HttpClient.post({
            path,
            body: adjustedQuestion
          });
          const newQuestionIndex = allQuestions.findIndex(q => q.id === questionToClone.id) + 1;
          updateCurrentBlockQuestions(
            questions => [...questions.slice(0, newQuestionIndex), adjustedQuestion, ...questions.slice(newQuestionIndex)]
          );

          function navigateToClonedQuestion() {
            if (current.blockId) {
              return navigateToBlockQuestion(current.blockId, adjustedQuestion.id);
            }
            if (current.fallback) {
              return navigateToFallbackQuestion(adjustedQuestion.id);
            }
            if (current.silenceFallback) {
              return navigateToSilenceFallbackQuestion(adjustedQuestion.id);
            }
            if (current.callbackId) {
              return navigateToCallbackQuestion(current.callbackId, adjustedQuestion.id);
            }
            return navigateToBlockQuestion(selectedBlockId, adjustedQuestion.id);
          }
          navigateToClonedQuestion();
        },
        save: async (questionToSave: BlockQuestion) => {
          await savePendingVariables();
          const baseUrl = () => {
            if (current.blockId) {
              return `/bots/${currentBotId}/creator/blocks/${current.blockId}/questions`;
            }
            if (current.fallback) {
              return `/bots/${currentBotId}/creator/fallback/step`;
            }
            if (current.silenceFallback) {
              return `/bots/${currentBotId}/creator/silence-fallback/step`;
            }
            if (current.callbackId) {
              return `/bots/${currentBotId}/creator/callbacks/${current.callbackId}/steps`;
            }
            return `/bots/${currentBotId}/creator/blocks/${selectedBlockId}/questions`;
          }
          if (newQuestion && newQuestion.id === questionToSave.id) {
            try {
              await HttpClient.post({
                path: baseUrl(),
                body: questionToSave
              });
              resetValidation()
              removeNewQuestion();
              updateCurrentBlockQuestions(questions => [...questions, questionToSave]);

            } catch (error) {
              handleErrors(questionToSave, error);
            }
          } else {
            try {
              await HttpClient.put({
                path: `${baseUrl()}/${questionToSave.id}`,
                body: questionToSave
              });
              resetValidation()
              updateCurrentBlockQuestions(questions => questions.map(question => question.id === questionToSave.id ? questionToSave : question));
            } catch (error) {
              handleErrors(questionToSave, error);
            }
          }
        },
        new: newQuestion,
      },
      intents: {
        current: currentIntent,
        get: () => intents,
        remove: async (removed: SurveyIntent) => {
          await HttpClient.delete({ path: `/bots/${currentBotId}/creator/intents/${encodeURIComponent(removed.name)}` });
          setIntents((prevIntents) => prevIntents.filter(block => block.name !== removed.name));
        },
        updateName: async (currentIntentName: string, newIntentName: string) => {
          const intentToChange = allIntents.find(intent => intent.name === currentIntentName);
          setAllIntents(allIntents.map((el: Intent) => el.name === intentToChange.name ? {
            ...el,
            displayName: newIntentName
          } : el));
          await HttpClient.patch({
            path: `/bots/${currentBotId}/intents/${encodeURIComponent(intentToChange.name)}`,
            body: { displayName: newIntentName }
          });
        },
        assignGroup: async (intentName: string, group: string) => {
          await changeIntentGroup(intentName, group);
        },
        removeFromGroup: async (intentName: string) => {
          await changeIntentGroup(intentName, undefined);
        },
        removeGroup: async (group: string) => {
          const intentsToRemoveGroup = intents.filter(intent => intent.group === group);
          setIntents((prevIntents) => {
            return prevIntents.map(intent => intent.group === group ? _.omit(intent, 'group') as SurveyIntent : intent);
          });
          await intentsToRemoveGroup.map(intent => updateIntent(_.omit(intent, 'group') as SurveyIntent))
        },
        groups: () => {
          return _.uniq(intents.map(intent => intent.group).filter(_.negate(_.isUndefined)))
        },
        add: async (intentName, messages) => {
          const intentAlreadyExists = allIntents
            .some(existingIntent => existingIntent.name === intentName || existingIntent.displayName === intentName);
          const intent: WordSurveyIntent = {
            name: intentName,
            question: questionFactory.confirmation(messages),
            entities: []
          };
          if (!intentAlreadyExists) {
            await HttpClient.post({ path: `/bots/${currentBotId}/intents`, body: { name: intentName } });
            setAllIntents([...allIntents, intent]);
          }
          setNewIntent(intent);
        },
        new: newIntent,
        close: () => {
          setNewIntent(undefined);
        },
        save: async (intent: SurveyIntent) => {
          if (newIntent) {
            await HttpClient.post({ path: `/bots/${currentBotId}/creator/intents`, body: intent });
            setIntents(prevIntents => [...prevIntents, intent]);
            setNewIntent(undefined);
            navigateToIntent(intent.name)
          } else {
            const intentToSave = {
              ...intent,
              group: currentIntent.group
            };
            await HttpClient.put({
              path: `/bots/${currentBotId}/creator/intents/${encodeURIComponent(intentToSave.name)}`,
              body: intentToSave
            });
            setIntents(prevIntents => prevIntents.map(item => item.name === intentToSave.name ? intentToSave : item));
          }

        }
      },
      callbacks: {
        current: callbacks[current.callbackId],
        callFinished: callbacks.callFinished,
        voiceMail: callbacks.voiceMailDetected,
        all: callbacks,
        get: () => callbacks[current.callbackId],
        update: async (callbackId: string, callback: any) => {
          updateCallback(callbackId, callback.steps);
          await HttpClient.put({
            path: `/bots/${currentBotId}/creator/callbacks/${callbackId}`,
            body: callback
          })
        },
        remove: async (callbackId: string) => {
          removeCallback(callbackId)
          await HttpClient.delete({
            path: `/bots/${currentBotId}/creator/callbacks/${callbackId}`
          })
        },
        add: async (callbackId: string) => {
          await HttpClient.post({ path: `/bots/${currentBotId}/creator/callbacks`, body: { callbackId } });
          addCallback(callbackId);
        }

      },
      steps: {
        get: () => allSteps,
      },
      entities: {
        details: () => entitiesDetails,
        usedInAnswers: () => entitiesUsedInAnswers,
      },
      blocksRef,
      executableFunctions: {
        get: () => executableFunctionDefinitions,
      },
      isLoading: definitionState === 'loading',
    }
  };
};


export type BlockGroup = {id: string, name: string, type: 'module' | 'group', blocks: Block[]};

export function addBlockToGroup(blocks: Block[], blockId: string, groupId: string) {
  return blocks.map((block) => {
    if (block.id === blockId) {
      return { ...block, groupId };
    }
    return block;
  });
}

export function removeBlocksFromGroup(blocks: Block[], blockId: string[]) {
  return blocks.map((block) => {
    if (blockId.includes(block.id)) {
      return _.omit(block, 'groupId');
    }
    return block;
  });
}

export function removeBlocks(blocks: Block[], blockIds: string[]) {
    return blocks.filter(block => !blockIds.includes(block.id));
}


export function reorderBlocks(
    blocks: Block[],
    blocksId: string | string[],
    afterId: string | null
) {
  const movingBlocks = blocks.filter((block) => blocksId.includes(block.id));
  const blocksWithNewOrder = blocks.filter((block) => !blocksId.includes(block.id));
  const afterIndex = afterId ? blocksWithNewOrder.findIndex((block) => block.id === afterId) : -1;
  blocksWithNewOrder.splice(afterIndex + 1, 0, ...movingBlocks);

  return blocksWithNewOrder;
}


function removeGroup(prevGroups: Group[], groupId: string) {
  return prevGroups.filter(group => group.id !== groupId);
}



function reorderGroups(blocks: Block[], groupId: string, afterId: string) {
  const blockIdsInGroup = blocks
      .filter((block) => block.groupId === groupId)
      .map((block) => block.id);

  return reorderBlocks(blocks, blockIdsInGroup, afterId);
}
