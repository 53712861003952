import _ from 'lodash';
import * as React from 'react';

import {GroupIcon} from '../../../icons';
import {AddItemModal} from '../AddItemModal/AddItemModal';

import {MenuOption} from './OptionsMenu';

type Group = { id: string, name: string };

type GroupOptionProps = {
  onCreateGroup: (groupName: string) => void,
  onSelectGroup: (groupId: string) => void,
  items: Group[],
  inputPrefix?: string
}

export const GroupOption: React.FC<GroupOptionProps> = ({onCreateGroup, onSelectGroup, items, inputPrefix = ''}) => {
    return (
        <>
            <AddItemModal
                addLabelId={'survey-creator.addNewGroup'}
                placeholderLabelId={'survey-creator.assignToGroupPlaceholder'}
                onCreate={(groupName) => onCreateGroup(_.trim(groupName))}
                onSelect={(groupId) => onSelectGroup(_.trim(groupId))}
                dataTest={'assign-group'}
                items={items.map(item => ({key: item.id, name: item.name}))}
                newItemFormatter={(text) => `${inputPrefix}${text}`}
                inputPrefix={inputPrefix}
                renderTrigger={({open}) => (
                        <MenuOption dataTest="group-option" onClick={open} icon={GroupIcon} hoverType={'regular'}
                        tooltipLabelId="menuOption.group.tooltip"/>)}
            />
        </>
  );
};
