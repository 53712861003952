import * as React from 'react';
import {useRef, useState} from 'react';
import {MenuOption} from './OptionsMenu';
import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal';
import {FormattedMessage} from 'react-intl';
import {CancelIcon} from '../../../icons';

type UngroupProps = {
    onUngroup: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const UngroupOption: React.FC<UngroupProps> = ({onUngroup}) => {
    const [showModal, toggleModal] = useState<boolean>(false);
    return (
        <>
            <MenuOption
                dataTest="ungroup-option"
                onClick={() => toggleModal(true)}
                icon={CancelIcon}
                hoverType="warning"
                tooltipLabelId="menuOption.ungroup.tooltip"
            />
            <div>
                {
                    showModal && (
                        <ConfirmationModal
                            onConfirm={(event) => {
                                onUngroup(event);
                                toggleModal(false);
                            }}
                            onCancel={() => toggleModal(false)}
                            renderText={() => <FormattedMessage id={'survey-creator.ungroup'}/>}
                            okLabelId={'survey-creator.ungroup.confirm'}
                            cancelLabelId={'survey-creator.ungroup.cancel'}
                        />
                    )
                }
            </div>
        </>
    );
};