import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import { Question as QuestionViewValue } from '../';
import { DictionaryQuestionMapper, Model as QuestionModelValue } from '../services';
import { useCurrentBot } from '../../../utils/CurrentBot';

type DictionaryQuestionAnswerTabReturnType = [QuestionViewValue, (v: QuestionViewValue) => void];

export function useDictionaryQuestionAnswerTabValues(question: QuestionModelValue, onChange: (m: QuestionModelValue) => void) {
    const [viewValue, setViewValue] = useState(null);
    const mapperRef = useRef<DictionaryQuestionMapper>();
    const bot = useCurrentBot();

    const handleViewValueChange = useCallback((v: QuestionViewValue): void => {
        setViewValue(v);
    }, []);

    useEffect(() => {
        mapperRef.current = new DictionaryQuestionMapper(bot.id, bot.languageCode);
    }, []);

    // WATCH OUT: current implementation requires 2 iterations of model->view mapping due to incomplete information before first model->view mapping.
    // WATCH OUT: this is why, we need to compare with _.isEqual.
    // MODEL -> VIEW
    useEffect(() => {
        const handleChange = async () => {
            const view = await mapperRef.current.getViewValue(question);
            // we need to compare value without undefined keys.
            const cleanView = _.pickBy(view, (value) => value !== undefined);
            if (_.isEqual(cleanView, viewValue)) {
                return;
            }
            // if we there's a difference, we update with undefined keys
            setViewValue(view);
        }

        handleChange();
    }, [question]);

    // WATCH OUT: current implementation requires 2 iterations of view->model mapping due to incomplete information after first view->model mapping.
    // WATCH OUT: this is why, we need to compare with _.isEqual.
    // VIEW -> MODEL
    useEffect(() => {
        const handleChange = () => {
            if (!viewValue) {
                return;
            }
            const modelValue = mapperRef.current.getModelValue(viewValue);
            // we need to compare value without undefined keys.
            const cleanModel = _.pickBy(modelValue, (value) => value !== undefined);
            if (_.isEqual(cleanModel, question)) {
                return;
            }
            // if we there's a difference, we update with undefined keys
            onChange(modelValue);
        }

        handleChange();
    }, [viewValue]);

    return useMemo<DictionaryQuestionAnswerTabReturnType>(() => ([
        viewValue,
        handleViewValueChange,
    ]), [viewValue, handleViewValueChange]);
}

export default useDictionaryQuestionAnswerTabValues;
