import classNames from 'classnames';
import * as React from 'react';
import {useContext} from 'react';
import {FormattedMessage} from 'react-intl';

import {ModuleIcon} from '../../icons';
import {CallbackIcon} from '../../icons/CallbackIcon';
import {IntentIcon} from '../../icons/IntentIcon';
import {callbackTypes} from '../model';
import {SurveyContext} from '../SurveyCreator';

import styles from './AddBlockMenu.pcss';

type Props = {
  onAddBlockClick: (e: any) => void
  onAddIntentClick: (e: any) => void
  onAddCallbackClick: (e: any) => void
  dataTest?: string;
}

interface AddBlockItem {
  type: BlockType;
  icon: any;
  dataTest: string;
}

export enum BlockType {
  Block = 'Block',
  Intent = 'Intent',
  Callback = 'Callback',
}

const items: AddBlockItem[] = [
  {type: BlockType.Callback, icon: CallbackIcon, dataTest: 'add-callback-option'},
  {type: BlockType.Intent, icon: IntentIcon, dataTest: 'add-intent-option'},
  {type: BlockType.Block, icon: ModuleIcon, dataTest: 'add-block-option'},
];

type ItemClick = (type: BlockType, e: any) => void;
type ItemProps = { onClick: ItemClick, item?: AddBlockItem, disabled?: Boolean, dataTest?: string };
const Item = (props: ItemProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.onClick(props.item.type, event)
    }
  };

  return (
    <>
      <div className={classNames(styles.optionContainer, {[styles.disabled]: props.disabled})}
           onClick={handleClick}
           data-add-block-type={props.item.type}
           data-test={props.item.dataTest}
      >
        <div className={styles.iconContainer}>
          <props.item.icon animationClass={styles.icon} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.text}>
            <FormattedMessage id={`survey-creator.addBlockItem${props.item.type}Label`} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={!props.disabled
              ? `survey-creator.addBlockItemDescription${props.item.type}`
              : `survey-creator.addBlockItemDescription${props.item.type}Disabled`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const AddBlockMenu = (props: Props) => {
  const {callbacks} = useContext(SurveyContext);
  const addClick: ItemClick = ((type, e) => {
    switch (type) {
      case BlockType.Intent: {
        props.onAddIntentClick(e);
        break;
      }
      case BlockType.Block: {
        props.onAddBlockClick(e);
        break;
      }
      case BlockType.Callback: {
        props.onAddCallbackClick(e);
        break;
      }
      default:
        throw new Error('unknown block type: ' + type);
    }
  });

  return <div className={styles.container}>
    {
      items.map((item, index) => {
        const disabled = item.type === BlockType.Callback && Object.keys(callbacks.all).length === callbackTypes.length;
        const isLast = index === items.length - 1;
        return (
          <React.Fragment key={item.type}>
            <Item onClick={addClick} item={item} disabled={disabled} />
            {!isLast && <div className={styles.horizontalSeparator} />}
          </React.Fragment>

        );
      })
    }
  </div>;
};
