import {BlockQuestion} from '../model';

export const addOrdinalNumberToBlockQuestionName = (blockQuestionWithName: BlockQuestion, ordinalNumber: number): BlockQuestion => (
    {...blockQuestionWithName, name: `${blockQuestionWithName.name} ${ordinalNumber}`}
)

export function calculateItemsInGroupHeight(isCollapsed: boolean, itemsLength: number) {
    const ITEM_HEIGHT_PX = 50;
    const ITEM_MARGIN = 8;
    return isCollapsed ? 0 : itemsLength * ITEM_HEIGHT_PX - ITEM_MARGIN;
}
