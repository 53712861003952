import * as React from 'react';

import styles from './IntentGroupOptions.pcss';
import {OptionsComponent} from './optionsMenu/Options';
import {RemoveGroupOption} from './optionsMenu/RemoveGroupOption';
import {RenderOptionsParams} from './pill/Pill';
import _ from 'lodash';
import {UngroupOption} from './optionsMenu/UngroupOption';
import {EditOption} from './optionsMenu/EditOption';

type Props = {
  onDelete: () => void;
  onUngroup?: () => void;
} & RenderOptionsParams;

export const GroupOptionsComponent = ({onDelete, onUngroup, onEditStart}: Props) => {
  const options = [
    onEditStart && <EditOption key={'edit-option'} onClick={onEditStart} />,
    onUngroup && <UngroupOption key={'ungroup-option'} onUngroup={onUngroup} />,
    <RemoveGroupOption key={'remove-group-option'} onDelete={onDelete} />,
  ];

  return (
    <OptionsComponent
      containerPositionClassName={styles.optionsPosition}
      options={_.compact(options)} />
  );
};
