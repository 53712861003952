import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {useAudioPlayer} from '../../../surveyCreator/components/useAudioPlayer'
import {useCancelChanges, usePatchConfig, useSurveyConfig, useSurveyScheduleStatus} from '../Settings.hooks'
import {updateModifiedConfig} from '../store/actions'

import voices from './Voice/voices.data';
import {useIntl} from 'react-intl';
import {SurveyConfigType} from '../store/types';

const getDefaultVoiceConfig = (config?: SurveyConfigType) => config?.voiceConfigs?.find(voice => voice.default) || config?.voiceConfigs?.[0];
const getDefaultVoiceConfigIndex = (config?: SurveyConfigType) => {
    const foundDefaultIndex = config?.voiceConfigs?.findIndex(voice => voice.default);
    return foundDefaultIndex >= 0 ? foundDefaultIndex : 0;
}

export const useGeneralTabProps = () => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const {savedConfig, modifiedConfig, displayedConfig, ...meta} = useSurveyConfig()
    const {isSurveyInProgress} = useSurveyScheduleStatus();
    const [voiceSampleText, setVoiceSampleText] = useState(intl.messages['survey.config.voiceConfig.defaultContent'] as string);

    const updateVoiceConfigProperty = useCallback((propertyName: string, newValue: string) => {
        const updatedData = { ...modifiedConfig, voiceConfigs: modifiedConfig.voiceConfigs.map((vc, index) => (index !== getDefaultVoiceConfigIndex(modifiedConfig) ? vc : { ...vc, [propertyName]: newValue })) };
        dispatch(updateModifiedConfig(updatedData));
    }, [modifiedConfig, dispatch])

    useEffect(() => {
        const defaultVoiceConfig = getDefaultVoiceConfig(modifiedConfig);

        if (!meta.isReady || !defaultVoiceConfig?.languageCode) {
            return;
        }

        const voiceLanguage = defaultVoiceConfig.languageCode;
        const voiceNameOptions = voiceLanguage
            ? voices.filter(el => el.languageCodes.includes(voiceLanguage))
            : [];

        if (voiceNameOptions.map(el => el.name).includes(defaultVoiceConfig.voiceName)) {
           return;
        }

        updateVoiceConfigProperty('voiceName', _.head(voiceNameOptions).name);
    }, [meta.isReady, getDefaultVoiceConfig(modifiedConfig)?.languageCode]);

    const updateTimezone = useCallback((timeZone: string) => {
        dispatch(updateModifiedConfig(merge(cloneDeep(modifiedConfig), {
            timeZone
        })))
    }, [modifiedConfig, dispatch])

    const updateFlag = useCallback((value: boolean, flag: string) => {
        dispatch(updateModifiedConfig(merge(cloneDeep(modifiedConfig), {
            [flag]: value
        })))
    }, [modifiedConfig, dispatch])

    const onSave = usePatchConfig();
    const onCancel = useCancelChanges();

    const handleSynthesize = useAudioPlayer({
        url: `/voice/synthesize-sample`,
        params: {
            text: encodeURIComponent(voiceSampleText),
            ...getDefaultVoiceConfig(displayedConfig),
        }
    });

    return {
        displayedConfig,
        voiceSampleText,
        isSurveyInProgress,
        setVoiceSampleText,
        updateVoiceConfigProperty,
        updateTimezone,
        updateFlag,
        onSave,
        onCancel,
        handleSynthesize,
        getDefaultVoiceConfig,
        ...meta
    }
}
