import classNames from 'classnames';
import * as React from 'react';
import {useRef, useState} from 'react';

import {MenuIcon} from '../../../icons';
import {useOutsideClick} from '../useOutsideClick';

import styles from './Options.pcss';
import {OptionsMenuComponent} from './OptionsMenu';

type Props = {
    options: any[];
    iconContainerClass?: string;
    iconClass?: string;
    containerPositionClassName?: string;
};


export const OptionsComponent = (props: Props) => {
    const [showOptions, toggleOptions] = useState<boolean>(false);
    const optionsRef = useRef(null);
    const optionsOutsideClick = useOutsideClick(optionsRef, () => {
        toggleOptions(false)
    });

    const optionsMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        toggleOptions(true);
        optionsOutsideClick.addHandler();
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        toggleOptions(false);
    }
    return (
        <>
            <div className={classNames([styles.options, props.iconContainerClass])}
                 data-test={'options-menu-icon'}
                 onClick={optionsMenuClick}>
                <MenuIcon animationClass={classNames(styles.optionsIcon, props.iconClass)}/>
            </div>
            {
                showOptions &&
                <OptionsMenuComponent
                    dataTest={'options-menu'}
                    containerPositionClassName={props.containerPositionClassName}
                    ref={optionsRef}
                >
                    {props.options.map((OptionComponent => (
                        React.cloneElement(OptionComponent, {
                            onClick: (event) => {
                                if (OptionComponent.props.onClick) {
                                    OptionComponent.props.onClick(event);
                                }
                                handleClick(event);
                            }
                        }))))}
                </OptionsMenuComponent>
            }

        </>
    )
};
