import * as React from 'react';
import {useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal';
import {DeleteIcon} from '../../../icons';

import {MenuOption} from './OptionsMenu';

type DeleteOptionProps = {
    onDelete: (event: any) => void
}

export const RemoveGroupOption: React.FC<DeleteOptionProps> = ({onDelete}) => {
    const [showModal, toggleModal] = useState<boolean>(false);
    return (
        <>
                <MenuOption
                    dataTest="remove-group-option"
                    onClick={() => toggleModal(true)}
                    icon={DeleteIcon}
                    hoverType={'warning'}
                    tooltipLabelId="menuOption.removeGroup.tooltip"
                />
            <div>
                {
                    showModal && (
                        <ConfirmationModal
                            onConfirm={(event) => {
                                onDelete(event);
                                toggleModal(false);
                            }}
                            onCancel={() => toggleModal(false)}
                            renderText={() => <FormattedMessage id={'survey-creator.removeGroup'}/>}
                            okLabelId={'survey-creator.removeGroup.confirm'}
                            cancelLabelId={'survey-creator.removeGroup.cancel'}
                        />
                    )
                }
            </div>
        </>
    );
};
