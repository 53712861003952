import {StepElementProps} from '../StepElementProps';
import {ToggleWithLabelAndElement} from '../ToggleWithLabelAndElement';
import styles from './Dtmf.pcss';
import {ElementWithLabel} from '../../../components/ElementWithLabel/ElementWithLabel';
import {NumberInputWithStepper} from '../../../components/NumberInputWithStepper/NumberInputWithStepper';
import * as React from 'react';
import {DtmfOptions, RecognizerSource} from '../../model';

const ONE_SECOND = 1_000;
const [DEFAULT_MIN_DIGITS, DEFAULT_MAX_DIGITS, DEFAULT_DIGITS_TIMEOUT, DEFAULT_MAX_TIMEOUT] = [5, 11, 3000, 12000]
const [DIGITS_TIMEOUT_MIN_VALUE, DIGITS_TIMEOUT_MAX_VALUE] = [ONE_SECOND, 10 * ONE_SECOND];
const [MAX_TIMEOUT_MIN_VALUE, MAX_TIMEOUT_MAX_VALUE] = [5 * ONE_SECOND, 60 * ONE_SECOND];
const MS_STEP = 500

type DtmfOptionsProps = {
    inputType?: RecognizerSource[]
    dtmfOptions?: DtmfOptions
};

export const Dtmf = ({onChange, question}: StepElementProps<DtmfOptionsProps>) => {
    const setDtmfOption = (optionKey: keyof DtmfOptions, value: number) => {
        onChange({
            ...question,
            dtmfOptions: {
                ...question.dtmfOptions,
                [optionKey]: value
            }
        });
    };

    const setMaxDigits = (value: number) => setDtmfOption('maxDigits', value);
    const setMinDigits = (value: number) => setDtmfOption('minDigits', value);
    const setDigitsTimeout = (value: number) => setDtmfOption('digitsTimeoutMs', value);
    const setMaxTimeout = (value: number) => setDtmfOption('maxTimeoutMs', value);


    const inputTypes = question.inputType || [RecognizerSource.SPEECH];
    const isEnabled = inputTypes.includes(RecognizerSource.DTMF);
    const toggleAnswers = () => {
        if (isEnabled) {
            onChange({
                ...question,
                inputType: inputTypes.filter(type => type !== RecognizerSource.DTMF),
                dtmfOptions: undefined
            });
        } else {
            onChange({
                ...question,
                inputType: [...inputTypes, RecognizerSource.DTMF],
                dtmfOptions: {
                    minDigits: DEFAULT_MIN_DIGITS,
                    maxDigits: DEFAULT_MAX_DIGITS,
                    digitsTimeoutMs: DEFAULT_DIGITS_TIMEOUT,
                    maxTimeoutMs: DEFAULT_MAX_TIMEOUT
                }
            });
        }
    };


    const minDigitsValue = question.dtmfOptions?.minDigits;
    const maxDigitsValues = question.dtmfOptions?.maxDigits;
    const digitsTimeoutSecondsValue = question.dtmfOptions?.digitsTimeoutMs;
    const maxTimeoutMsValue = question.dtmfOptions?.maxTimeoutMs;

    return (
        <ToggleWithLabelAndElement value={isEnabled} onToggle={toggleAnswers} labelId="survey-creator.dtmf.label" dataTest="dtmf-toggle">
            <>
                <ElementWithLabel labelId="survey-creator.dtmf.inputDescription" inner className={styles.descriptionLabel} />
                {isEnabled && (
                    <div className={styles.options}>
                        <ElementWithLabel labelId="survey-creator.dtmf.minDigits" className={styles.option}>
                            <NumberInputWithStepper dataTest="dtmf-min-digits" minValue={1} maxValue={maxDigitsValues} value={minDigitsValue} onChange={setMinDigits}/>
                        </ElementWithLabel>

                        <ElementWithLabel labelId="survey-creator.dtmf.maxDigits" className={styles.option}>
                            <NumberInputWithStepper dataTest="dtmf-max-digits" minValue={minDigitsValue} value={maxDigitsValues} onChange={setMaxDigits}/>
                        </ElementWithLabel>

                        <ElementWithLabel labelId="survey-creator.dtmf.digitsTimeout" className={styles.option}>
                            <NumberInputWithStepper value={digitsTimeoutSecondsValue}
                                                    onChange={setDigitsTimeout}
                                                    minValue={DIGITS_TIMEOUT_MIN_VALUE}
                                                    step={MS_STEP}
                                                    maxValue={DIGITS_TIMEOUT_MAX_VALUE}
                                                    dataTest="dtmf-digits-timeout"
                                                    seconds
                            />
                        </ElementWithLabel>

                        <ElementWithLabel labelId="survey-creator.dtmf.maxTimeout" className={styles.option}>
                            <NumberInputWithStepper
                                                    value={maxTimeoutMsValue}
                                                    onChange={setMaxTimeout}
                                                    step={MS_STEP}
                                                    minValue={MAX_TIMEOUT_MIN_VALUE}
                                                    maxValue={MAX_TIMEOUT_MAX_VALUE}
                                                    dataTest="dtmf-max-timeout"
                                                    seconds
                            />
                        </ElementWithLabel>
                    </div>
                )}
            </>
        </ToggleWithLabelAndElement>
    );
};
