import React from 'react';

import { SavableLayout } from '../../../components/layouts/SavableLayout/SavableLayout';
import { ToggleWithLabel } from '../../../surveyCreator/components/ToggleWithLabel';
import { GeneralTabContentComponentProps } from '../types';

import { Timezone } from './Timezone/Timezone';
import { Voice } from './Voice/Voice';

export const GeneralTabContentComponent: React.FC<GeneralTabContentComponentProps> = ({
  hasChanges,
  isReady,
  displayedConfig,
  isSurveyInProgress,
  updateVoiceConfigProperty,
  updateFlag,
  updateTimezone,
  onSave,
  onCancel,

  voiceSampleText,
  setVoiceSampleText,
  handleSynthesize,
  getDefaultVoiceConfig,
}) => <SavableLayout
  isLoading={!isReady}
  hasChanges={hasChanges}
  onSave={onSave}
  onCancel={onCancel}
  dataTest="general-tab-content"
>
    <ToggleWithLabel
      value={displayedConfig?.anonymized}
      onToggle={() => updateFlag(!displayedConfig?.anonymized, 'anonymized')}
      labelId={'survey.config.anonymized'}
      dataTest={'anonymized-toggle'}
    />
    <Timezone
      disabled={isSurveyInProgress}
      timezone={displayedConfig?.timeZone}
      updateTimezone={updateTimezone}
    />
    <Voice
      voiceLanguage={getDefaultVoiceConfig(displayedConfig)?.languageCode}
      voiceName={getDefaultVoiceConfig(displayedConfig)?.voiceName}
      tempo={Number(getDefaultVoiceConfig(displayedConfig)?.speakingRate || 1)}
      pitch={Number(getDefaultVoiceConfig(displayedConfig)?.pitch || 0)}
      updateVoiceConfigProperty={updateVoiceConfigProperty}
      voiceSampleText={voiceSampleText}
      setVoiceSampleText={setVoiceSampleText}
      handleSynthesize={handleSynthesize}
    />
  </SavableLayout>;
